<template>
  <PageRoot>
    <PageBody class="py-24">
      <BlockContent class="text-center">
        <h1 class="page-title">
          404
        </h1>
        <h2 class="subpage-title">
          Sorry! Address not found.
        </h2>
        <div class="flex justify-center gap-4 py-16">
          <TopLevelButtons>
            <template #after>
              <AButton
                to="/"
                label="Back Home"
                icon="i-carbon-home"
              />
            </template>
          </TopLevelButtons>
        </div>
      </BlockContent>
    </PageBody>
  </PageRoot>
</template>
